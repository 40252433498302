import '../bootstrap-scss/custom.scss';
import './assets/css/icofont.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Header from './components/header';
import Footer from './components/footer';

// Code-splitting is automated for routes
import Home from './routes/home';
import Policy from './routes/policy'
import Contact from './routes/contact'

export default class App extends Component {

	render() {
		return (
			<div id="app">
				<Header />
				
				<Router onChange={this.handleRoute}>
					<Home path="/"  />
					<Policy path="/policy" />
					<Contact path="/contact" />
				</Router>

				<div className="at-footer">
					<Footer />
				</div>
			</div>
		);
	}
}