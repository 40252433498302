import { h } from 'preact';

const Footer = () => (
    <>
        <section key="footer" class="download-area ptb-90" id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="sec-title">
                            <h2>Download Available<span class="sec-title-border"></span></h2>
                            <p>Multiple platforms supported - Android & IOS</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <ul>
                            <li>
                                <a href="#" class="download-btn flexbox-center">
                                    <div class="download-btn-icon">
                                        <i class="icofont icofont-brand-android-robot"></i>
                                    </div>
                                    <div class="download-btn-text">
                                        <p>Available on</p>
                                        <h4>Android Store</h4>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="download-btn flexbox-center">
                                    <div class="download-btn-icon">
                                        <i class="icofont icofont-brand-apple"></i>
                                    </div>
                                    <div class="download-btn-text">
                                        <p>Available on</p>
                                        <h4>Apple Store</h4>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        
        <footer class="footer" id="contact">
            <div class="container">
                {/*
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="contact-form text-center">
                        <h4>Get in Touch</h4>
                        <p class="form-message"></p>
                        <form id="contact-form" action="#" method="POST">
                            <input type="text" name="name" placeholder="Enter Your Name" />
                            <input type="email" name="email" placeholder="Enter Your Email" />
                            <input type="text" name="subject" placeholder="Your Subject" />
                            <textarea placeholder="Messege" name="message"></textarea>
                            <button type="submit" name="submit">Send Message</button>
                        </form>
                    </div>
                </div>
                
                <div class="col-lg-6">
                    <div class="contact-address">
                        <h4>Address</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                        <ul>
                            <li>
                                <div class="contact-address-icon">
                                    <i class="icofont icofont-headphone-alt"></i>
                                </div>
                                <div class="contact-address-info">
                                    <a href="callto:#">+8801712435941</a>
                                    <a href="callto:#">+881934180093</a>
                                </div>
                            </li>
                            <li>
                                <div class="contact-address-icon">
                                    <i class="icofont icofont-envelope"></i>
                                </div>
                                <div class="contact-address-info">
                                    <a href="mailto:#">Jsoftbd87gmail.com</a>
                                </div>
                            </li>
                            <li>
                                <div class="contact-address-icon">
                                    <i class="icofont icofont-web"></i>
                                </div>
                                <div class="contact-address-info">
                                    <a href="www.jsoftbd.com">www.jsoftbd.com</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            */}

                {/*
            <div class="row">
                <div class="col-lg-12">
                    <div class="subscribe-form">
                        <form action="#">
                            <input type="text" placeholder="Your email address here" />
                            <button type="submit">Subcribe</button>
                        </form>
                    </div>
                </div>
            </div>
            */}

                <div class="row">
                    <div class="col-lg-12">
                        <div class="copyright-area">
                            {/*
                        <ul>
                            <li><a href="#"><i class="icofont icofont-social-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-brand-linkedin"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-pinterest"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-google-plus"></i></a></li>
                        </ul>
                        */}
                            <p>&copy; Copyright &copy;{new Date().getFullYear()} All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

            <a href="#" class="scrollToTop">
                <i class="icofont icofont-arrow-up"></i>
            </a>
        </footer>
    </>
);

export default Footer;