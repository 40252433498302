import { h, Component } from "preact";
import { Link } from "preact-router/match";

export default class Header extends Component {

    componentDidMount() {
        if (!document) return

        window.onscroll = () => {
            if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
                console.log('- scrolled')
                this.header.className = 'header scrolled'
            } else {
                this.header.className = 'header'
            }
        }
    }

    render() {
        return (
            <header class="header" ref={i => this.header = i}>
                <div class="container">
                    <div class="row flexbox-center">
                        <div class="col-lg-2 col-md-3 col-6">
                            <div class="logo">
                                <img src="assets/img/logo.svg" alt="BEEMF Beta Logo" />
                            </div>
                        </div>
                        <div class="col-lg-10 col-md-9 col-6">
                            <div class="responsive-menu"></div>
                            <div class="mainmenu">
                                <ul id="primary-menu">
                                    <li><Link className="nav-link" activeClassName="active" href="/">Home</Link></li>
                                    <li><Link className="nav-link" activeClassName="active" href="/policy">Policy</Link></li>
                                    <li><Link className="nav-link" activeClassName="active" href="/contact">Contact Us</Link></li>

                                    {/*
                                    <li><a class="nav-link" href="#feature">Feature</a></li>
                                    <li><a class="appao-btn" href="#">Download</a></li>
                                    */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}